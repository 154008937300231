import {
  http,
  HttpResponse,
  type JsonBodyType,
  type RequestHandler,
} from 'msw'
import { getClubByHash, getClubByShortName, getClubProfiles } from '@core/modules/Club/api'
import { getApiRoute } from '@core/utils/api'

import { getClubProfilesMock } from '@core/modules/Club/requestHandlers/mockData'
import { groupByClubLegacy } from '@dashboard/modules/ProfileSelector/requestHandlers/mockData'

export const getClubByHashHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubByHash.url, { params: { clubHash: groupByClubLegacy.club.id } }),
  ({ request, params, cookies }) => HttpResponse.json({
    '@context': '/api/contexts/Club',
    '@id': '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
    '@type': 'Club',
    hash: groupByClubLegacy.club.id,
    name: 'Club Deportivo Arsenaloooooo',
    publicPortal: true,
  }),
)

export const getClubByShortNameHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubByShortName.url, { params: { clubShortName: groupByClubLegacy.club.shortname } }),
  ({ request, params, cookies }) => HttpResponse.json({
    '@context': '/api/contexts/Club',
    '@id': '/api/clubs/3abf15a877a6dabfc67806017ab195526a3b011d',
    '@type': 'Club',
    hash: groupByClubLegacy.club.id,
    name: 'Club Deportivo Arsenaloooooo',
    publicPortal: true,
  }),
)

export const getClubProfilesHandler: RequestHandler = http.get(
  getApiRoute<{ clubHash: string }>(getClubProfiles.url, { params: { clubHash: groupByClubLegacy.club.id } }),
  () => HttpResponse.json(getClubProfilesMock as JsonBodyType),
)

export default [
  getClubByHashHandler,
  getClubProfilesHandler,
]
